<template>
  <div class="tinymce-editor">
    <!-- <Editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick">
    </Editor> -->
    <Editor v-model="myValue" :key="tkey" :init="init" :disabled="disabled" @onClick="onClick"> </Editor>
    <a-modal v-model="visible" title="上传视频进度" :zIndex=9999 :footer="null">
      <a-progress  :percent="progress" />
    </a-modal>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/plugins/media' // 插入视频插件
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver'
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/lists'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/emoticons'
import axios from 'axios'
export default {
  name: 'Tinymce',
  components: {
    Editor,
  },
  props: {
    // 传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 统一配置cdn前缀，如果不用cdn就把skins包整个复制到public/tinymce下
    cdnOrigin: {
      type: String,
      default: '/tinymce'
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount code paste hr emoticons',
    },
    toolbar: {
      type: [String, Array],
      default:
        'undo redo | forecolor backcolor  | bold italic | alignleft aligncenter alignright alignjustify  | bullist numlist outdent indent hr | lists image media table code emoticons paste| removeformat ',
    },
    height: {
      type: Number,
      default: 140,
    },
    tkey: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tinymceFlag: 1,
      visible:false,
      progress:0,
      // 初始化配置
      init: {
        language_url: 'https://oss.vclus.com/tinymce/zh_CN.js', // 语言包的路径
        language: 'zh_CN', // 语言
        skin_url: `${this.cdnOrigin}/skins/ui/oxide`, // skin路径
        content_css: `${this.cdnOrigin}/skins/ui/oxide/content.css`,
        height: 240, // 编辑器高度
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false, // 是否禁用“Powered by TinyMCE”
        menubar: false, // 顶部菜单栏显示
        file_picker_types: 'media',
        resVideo: null, //七牛云返回的视频url
        uploaded: false, //有没有上传完成
        toolbar_mode: 'sliding',
        powerpaste_word_import: 'propmt',
        powerpaste_html_import: 'propmt',
        powerpast_allow_local_images:true,
        paste_data_image:true,
        paste_webkit_styles: 'all',
        file_picker_callback: (callback, value, meta) => {
          if (meta.filetype == 'media') {
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'video/mp4,video/AVI,video/mov,video/FLV,video/rmvb,video/mtv,video/mpeg,video/m4v')
            const that = this
            input.onchange = function (e) {
              // const loading = that.$loading({
              //   lock: true,
              //   // text: '正在上传视频...',
              //   spinner: 'el-icon-loading',
              //   background: 'rgba(0, 0, 0, 0.7)',
              // })
              const file = this.files[0]

              const ext = file.name.split('.')[file.name.split('.').length-1]
              if (
                ext !== 'mp4' &&
                ext !== 'AVI' &&
                ext !== 'mov' &&
                ext !== 'FLV' &&
                ext !== 'rmvb' &&
                ext !== 'wmv' &&
                ext !== 'mtv' &&
                ext !== 'mpg' &&
                ext !== 'mpeg' &&
                ext !== 'm4v'
              ) {
                // that.$message.error({
                //   message: '上传资源只能是 AVI/mov/rmvb/FLV/mp4/wmv/mtv/mpg/mpeg 格式的视频!',
                //   duration: 5000,
                //   customClass: 'zZindex',
                // })
                alert('请上传视频')
                return false
              }
              that.uploadVideo(file, 'video',() => {
              if (that.uploaded) {
                callback(that.resVideo, { title: file.name }) //将url显示在弹框输入框中
              } else {
                setTimeout(() => {
                  callback(that.resVideo, { title: file.name })
                }, 2000)
              }
              }) // 上传视频拿到url
            }
            input.click()
          }
        },

        video_template_callback: function (data) {
          //指定多媒体返回格式
          return (
            '<video  src="' +
            data.source +
            '"  width="' +
            data.width +
            '" height="' +
            data.height +
            '"' +
            (data.poster ? ' poster="' + data.poster + '"' : '') +
            ' controls="controls">\n' +
            '<source src="' +
            data.source +
            '"' +
            (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') +
            ' />\n' +
            (data.altsource
              ? '<source src="' +
                data.altsource +
                '"' +
                (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : '') +
                ' />\n'
              : '') +
            '</video>'
          )
        },

        emoticons_database_url: `${this.cdnOrigin}/plugins/emoticons/js/emojis.js`,
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          // success(img)
          // const token = this.$store.getters.token
          this.axios
            .post(`/api/upload/qiniu/token?filenames=`)
            .then((response) => {
              const url = 'https://up-z2.qiniup.com'
              const res = response.body.data[0]
              const formData = new FormData()
              formData.append('token', res.token)
              formData.append('key', res.key)
              formData.append('file', blobInfo.blob(), url)
              axios({
                url: url,
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                data: formData,
              })
                .then((resData) => {
                  success(resData.data.domain + resData.data.url)
                })
                .catch((err) => {
                  console.log(err)
                })
            })
            .catch((err) => {
              // failure('出现未知问题，刷新页面，或者联系程序员')
              console.log(err)
            })
        },
      },
      myValue: this.value,
    }
  },

  activated() {
    this.tinymceFlag++
  },

  mounted() {
    tinymce.init({})
  },

  methods: {
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },

    onActivated() {
      console.log('++前',this.tinymceFlag)
      this.tinymceFlag++
      console.log('++后',this.tinymceFlag)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    },

    // 上传视频拿到url
    uploadVideo(file, type,callback) {
      this.axios
        .post(`/api/upload/qiniu/token?filenames=${file.name}`)
        .then((response) => {
          this.visible =true
          const url = 'https://up-z2.qiniup.com'
          const res = response.body.data[0]
          const formData = new FormData()
          formData.append('token', res.token)
          formData.append('key', res.key)
          formData.append('file', file, url)
          axios({
            url: url,
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
            onUploadProgress: (progress) => {
              this.progress = (progress.loaded / progress.total * 100 | 0)
            }
          })
            .then((resData) => {
              this.uploaded = true
              this.resVideo = resData.data.domain + resData.data.url
              typeof callback == "function" && callback()
              this.visible =false
              })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          // console.log('出现未知问题，刷新页面，或者联系程序员')
          console.log(err)
        })
    },
  },

  destroyed() {
    tinymce.remove()
  },

  beforeDestroy() {

  },

  watch: {
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
      this.$emit('update:value', newValue)
      this.$emit('change')
    },
  },
}
</script>
